import { Slider } from '@finn/ui-modules';

import {
  ContentItemCosmicMetadata,
  UIBaseCosmicObject,
} from '~/types/UIModules';
import ContentItem from '~/ui-elements/ContentItem';

type Props = {
  title: string;
  contents: UIBaseCosmicObject<ContentItemCosmicMetadata>[];
};

const ContentSlider = ({ title, contents }: Props) => {
  return (
    <Slider title={title} titleHeadingSize={2}>
      {contents?.map((content) => (
        <div className="h-full w-[280px] sm:w-[360px]" key={content.id}>
          <ContentItem item={content.metadata} />
        </div>
      ))}
    </Slider>
  );
};

export default ContentSlider;
